<template>
  <div class="profit-by-cash">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <div class="df-col">
        <!-- 月份选择器 -->
        <el-date-picker
          v-model="submitData.month_date"
          type="month"
          size="mini"
          value-format="yyyy-MM"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button @click="onSearchData" size="mini" type="primary"
          >查询</el-button
        >

        <!-- 导出表格按钮 -->
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 损益表按现金业绩 -->
    <div
      class="df-table"
      :style="[{ '--tableMaxHeight': tableMaxHeight + 'px' }]"
    >
      <table
        v-if="tableData != ''"
        id="exprot-table"
        cellpadding="0"
        cellspacing="0"
        v-loading="loading"
      >
        <tr class="total-row stripe">
          <td class="title-col">[现金业绩]</td>
          <td class="content-col">
            <span>{{ tableData.AllIncomeTotal }}</span>
          </td>
        </tr>
        
        <!-- 现金业绩下的子级业绩
             注意：这个v-for循环，目前是要求行的内容是写死的，所以整个表格所有的:class判断条件不需要改，
             除非现金业绩下的子集业绩要求每一行的title不固定显示，那可能需要重新构思表格要如何渲染了，
             仅仅只是更改排序或增加新行内容，只需要在perforRowList数组中继续添加对象或更改数组对象排序 -->
        <tr
          :class="tableRowClassName(index + 1)"
          v-for="(item, index) in perforRowList"
          :key="index"
        >
          <td class="title-col">{{ item.title }}</td>
          <td class="content-col">
            <span>{{ tableData[item.totalKey] }}</span>
            <div
              v-show="
                tableData[item.paymentKey] != '' && tableData[item.paymentKey]
              "
              class="payments"
            >
              (
              <span
                v-for="(item2, index2) in tableData[item.paymentKey]"
                :key="index2"
                >{{
                  (index2 != 0 ? "，" : "") + item2.Name + "：" + item2.Amount
                }}</span
              >
              )
            </div>
          </td>
        </tr>

        <tr class="total-row" :class="tableRowClassName(perforRowList.length + 1)">
          <td class="title-col">[营业支出]</td>
          <td class="content-col">
            <span>{{ tableData.SpendExpenseTotal }}</span>
          </td>
        </tr>

        <!-- 营业支出下的子级业绩，数据是全动态，:class同样不需要修改 -->
        <tr
          :class="tableRowClassName(index + (perforRowList.length % 2 == 0 ? 0 : 1))"
          v-for="(item, index) in tableData.SpendExpenseStatisList"
          :key="'SpendExpenseStatisList' + index"
        >
          <td class="title-col">{{ item.KindName }}</td>
          <td class="content-col">
            <span>{{ item.KindTotal }}</span>
          </td>
        </tr>

        <tr class="total-row" :class="tableRowClassName(tableData.SpendExpenseStatisList.length + (perforRowList.length % 2 == 0 ? 0 : 1))">
          <td class="title-col">净利润</td>
          <td class="content-col">
            <span style="color:red;">{{ tableData.ProfitAmount }}</span>
            <div
              v-show="tableData.ProfitAmount >= 0"
              class="payments"
              style="color: black;"
            >
              ( [现金业绩] - [营业支出] )
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!-- end 损益表按现金业绩 -->
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  data() {
    return {
      submitData: {
        // 提交数据
        month_date: "", // 月份
      },
      tableData: [], // 损益表数据
      perforRowList: [  // 现金业绩下的子级业绩列表 目前顺序写死，如需添加或更改排序，请参照下方的格式
        {
          id: 1,
          title: "劳动业绩",
          totalKey: "WorkPerforTotal",
          paymentKey: "WorkPerforPayments",
        },
        {
          id: 2,
          title: "外卖业绩",
          totalKey: "SellPerforTotal",
          paymentKey: "SellPerforPayments",
        },
        {
          id: 3,
          title: "开卡业绩",
          totalKey: "OpenCardPerforTotal",
          paymentKey: "OpenCardPerforPayments",
        },
        {
          id: 4,
          title: "充卡业绩",
          totalKey: "KeepCardPerforTotal",
          paymentKey: "KeepCardPerforPayments",
        },
        {
          id: 5,
          title: "还款业绩",
          totalKey: "RepayPerforTotal",
          paymentKey: "RepayPerforPayments",
        },
        {
          id: 6,
          title: "其他收入",
          totalKey: "SpendIncomeTotal",
          paymentKey: "SpendIncomePayments",
        }
      ],
      tableMaxHeight: "", // 表格最大高度
      loading: false, // 表格加载
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState()
    await this.onSearchData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  computed: {
    tableRowClassName() { // 表格行单元格斑马纹样式
      return function(event) {
        if (event % 2 == 0) {
          return "stripe";
        }
        return "";
      };
    },
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 查询现金业绩数据
    async onSearchData() {
      this.loading = true;
      try {
        let { data, errocde } = await Report.queryfinanceProfitByCash(
          this.submitData
        );
        this.tableData = data;
      } finally {
        this.loading = false;
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginMonth } = await this.$store.dispatch("onGetDateState");
      // this.multipleDate = [beginDay, endDay];
      this.submitData.month_date = beginMonth;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { monthTime: event });
      this.initDateState();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "现金业绩");
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.profit-by-cash {
  .df-row {
    display: flex;
    margin: 20px 0;
    .df-col {
      margin-right: 10px;
    }
  }

  .df-table {
    width: 100%;
    max-height: var(--tableMaxHeight); // 动态最大高度
    overflow-y: scroll; // 垂直滚动条

    table {
      width: 100%;
      border: 1px solid #eee;

      tr {
        display: flex;

        td {
          padding: 10px 20px;
          border: 1px solid #eee;
        }

        .title-col {
          width: 200px;
          text-align: right;
        }

        .content-col {
          text-align: left;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;

          > span {
            margin-right: 10px;
          }

          .payments {
            color: red;
          }
        }
      }
      .stripe {
        background-color: #f5f5f5;
      }

      tr:hover {
        background-color: #d5e4ff !important;
      }

      .total-row {
        td {
          font-weight: bold;
          text-align: left;
        }

        .content-col {
          padding: 10px;
        }
      }
    }
  }
}
</style>
